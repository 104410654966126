import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CarrinhoService } from '../carrinho/carrinho.service';
import { ValoresCarrinho } from 'src/app/interfaces/valoresCarrinho';

@Injectable({
    providedIn: 'root'
})
export class CalcularCarrinhoGuard {

    constructor(private carrinho: CarrinhoService) { }

    async resolve() {

        return await this.carrinho.calcularCarrinho();

    }

}
