import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from '../session/session.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
    providedIn: 'root'
})
export class ValidateIsLoggedGuard {

    constructor(private session: SessionService, private utils: UtilsService) {}

    async canActivate(): Promise<boolean> {

        if (!(await this.session.isLogged())) {
            this.utils.navigateRoot('identificacao');
            this.utils.exibirToast('Faça login para continuar.', 'error');
            return false;
        }

        return true;

    }

}
