import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private semana: object;
    private dias: object;
    private meses: object;

    constructor() {

        this.semana = environment.authApi.semana;
        this.dias = environment.authApi.dias;
        this.meses = environment.authApi.meses;

    }

    public gerarEndpoint() {
        const hoje = new Date();
        
        //@ts-ignore
        return `${this.semana[hoje.getDay()]}.${this.dias[hoje.getDate()]}.${this.meses[hoje.getMonth() + 1]}`;
    }

}
