import { Injectable } from '@angular/core';
import { CapacitorCookies } from '@capacitor/core';
import { LocalStorage } from '../../types/localstorage';

@Injectable({
    providedIn: 'root'
})
export class CookiesService {

    cookies: any = {};
    document: any = { cookie: '' };

    constructor() { }

    getItem(name: LocalStorage): string {
        console.log(name);
        const cookies = CapacitorCookies.getCookies();
        console.log(cookies);
        // if (cookies && name && typeof cookies[name] !== 'undefined') {
        //     return cookies[name] ?? '';
        // }

        return '';

    }

    setItem(
        name: LocalStorage,
        value: string,
        expires?: Date,
        path: string = '/'
    ): boolean {

        if (!name) {
            return false;
        }

        if (expires == undefined) {
            expires = new Date();
            expires.setFullYear(expires.getFullYear() + 1);
        }

        if (this.cookies == undefined) {
            this.cookies = [];
        }

        this.cookies[name] = value;
        CapacitorCookies.setCookie({ key: name, value, path, expires: expires.toLocaleDateString() })

        return true;

    }

    removeItem(name: string): boolean {

        if (!name) {
            return false;
        }

        if (this.cookies == undefined) {
            this.cookies = [];
        }

        CapacitorCookies.deleteCookie({ key: name })

        return true;

    }

}
