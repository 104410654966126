import { Component, Renderer2 } from '@angular/core';
import { ActivationEnd, ResolveEnd, Router } from '@angular/router';
import { Title } from "@angular/platform-browser";
import { environment } from "../environments/environment";
import { TitleCasePipe } from "@angular/common";
import { UtilsService } from './services/utils/utils.service';
import { LocalStorage } from './types/localstorage';

declare var window: any;
@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {

    private readonly FIRST_ACCESS_KEY: LocalStorage = 'usr_primeiro_acesso';

    constructor(
        private utils: UtilsService,
        private router: Router,
        private titleService: Title,
        private titleCasePipe: TitleCasePipe,
    ) { }

    async ngOnInit() {

        if (['android', 'ios'].includes(this.utils.plataforma().platform)) {
            window.screen.orientation.lock('portrait');
        }

        await this.inicializeApp();

        this.router.events.subscribe(async (event) => {

            if (event instanceof ActivationEnd) {

                let envName = this.titleCasePipe.transform(environment.envName);
                envName === 'Production' ? envName = '' : envName = `[${envName}] `;

                const tituloPagina = event.snapshot.data['tituloPagina'] ? ` - ${event.snapshot.data['tituloPagina']}` : '';

                if (tituloPagina != '') {
                    this.titleService.setTitle(`${envName}PowShare${tituloPagina}`);
                }

            }

            // First access
            if (event instanceof ResolveEnd) {

                if (await this.utils.checkStorage(this.FIRST_ACCESS_KEY)) {

                    this.utils.setStorage(this.FIRST_ACCESS_KEY, 'visited');

                }

            }

        });

    }

    async inicializeApp() {



    }

}
