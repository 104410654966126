<ion-content>
  <ion-row class="h-100">
    <ion-col size="12" class="d-flex fd-c ion-justify-content-center ion-align-items-center">
      <ion-item mode="ios" class="input-padrao" style="width: 176px">
        <ion-label position="stacked">Código:</ion-label>
        <ion-input class="input-borda input-branco" id="row-codigo" type="tel" placeholder="******"
          enterkeyhint="Próximo" maxlength="6" [(ngModel)]="codigo"
          [ngClass]="{'error-input': form.codigo.mostrarErro && codigo !== ''}">
        </ion-input>
        <p class="erro-input" *ngIf="form.codigo.mostrarErro">{{form.codigo.mensagem}}</p>

      </ion-item>
      <ion-button (click)="confirmarCodigo()" text="Confirmar Código" type="primary"></ion-button>
    </ion-col>
  </ion-row>
</ion-content>
<!-- <ion-content>
  <div class="padding">
    <p class="title">Recuperação de senha</p>

    <ion-range class="range" disabled value="50"></ion-range>

    <p>Digite o código de 6 dígitos que foi enviado para o e-mail {{ email?.email }}</p>
  </div>

  <main class="padding inputs">
    <code-input [codeLength]="6" (codeChanged)="alterouCampo($event)"
      (codeCompleted)="confirmarCodigo($event)"></code-input>
  </main>

  <div class="validar">
    <p>Validando código...</p>
  </div>

  <div class="codigo">
    <p>Não recebeu o código?</p>
    <a [routerDirection]="'back'" [routerLink]="['/recuperar-senha']">Enviar novamente</a>
  </div>
</ion-content> -->