import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

// Guards
import { ValidateIsLoggedGuard } from './services/guards/validate-is-logged.guard';
import { DadosHome } from './services/guards/dados-home.guard';
import { CalcularCarrinhoGuard } from './services/guards/calcular-carrinho.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: '',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
        data: {
            tituloPagina: 'Home'
        },
        resolve: {
            dados_home: DadosHome
        }
    },
    {
        path: 'image',
        loadChildren: () => import('./modals/image/image.module').then(m => m.ImagePageModule)
    },
    {
        path: 'modal-mensagem',
        loadChildren: () => import('./modals/modal-mensagem/modal-mensagem.module').then(m => m.ModalMensagemPageModule)
    },
    {
        path: 'identificacao',
        loadChildren: () => import('./pages/identificacao/identificacao.module').then(m => m.IdentificacaoPageModule),
        data: {
            tituloPagina: 'Identificação'
        }
    },
    {
        path: 'cadastrar',
        loadChildren: () => import('./pages/cadastrar/cadastrar.module').then(m => m.CadastrarPageModule),
        data: {
            tituloPagina: 'Criar conta'
        }
    },
    {
        path: 'institucional-joyers',
        loadChildren: () => import('./pages/institucional/institucional-joyers/institucional-joyers.module').then(m => m.InstitucionalJoyersPageModule),
        data: {
            tituloPagina: 'Joyers'
        }
    },
    {
        path: 'institucional-partners',
        loadChildren: () => import('./pages/institucional/institucional-partners/institucional-partners.module').then(m => m.InstitucionalPartnersPageModule),
        data: {
            tituloPagina: 'Partners'
        }
    },
    {
        path: 'institucional-members',
        loadChildren: () => import('./pages/institucional/institucional-members/institucional-members.module').then(m => m.InstitucionalMembersPageModule),
        data: {
            tituloPagina: 'Members'
        }
    },
    {
        path: 'fale-conosco',
        loadChildren: () => import('./pages/institucional/fale-conosco/fale-conosco.module').then(m => m.FaleConoscoPageModule),
        data: {
            tituloPagina: 'Fale conosco'
        }
    },
    {
        path: 'informacoes-conta',
        loadChildren: () => import('./pages/informacoes-conta/informacoes-conta.module').then(m => m.InformacoesContaPageModule),
        data: {
            tituloPagina: 'Minha conta'
        },
        canActivate: [ValidateIsLoggedGuard]
    },
    {
        path: 'sobre-nos',
        loadChildren: () => import('./pages/institucional/sobre-nos/sobre-nos.module').then(m => m.SobreNosPageModule),
        data: {
            tituloPagina: 'Sobre nós'
        }
    },
    {
        path: 'faq',
        loadChildren: () => import('./pages/institucional/faq/faq.module').then(m => m.FaqPageModule),
        data: {
            tituloPagina: 'Faq'
        }
    },
    {
        path: 'recuperar-senha',
        loadChildren: () => import('./pages/recuperar-senha/recuperar-senha.module').then(m => m.RecuperarSenhaPageModule)
    },
    {
        path: 'alterar-senha',
        loadChildren: () => import('./pages/alterar-senha/alterar-senha.module').then(m => m.AlterarSenhaPageModule)
    },
    {
        path: 'validar-codigo',
        loadChildren: () => import('./modals/validar-codigo/validar-codigo.module').then(m => m.ValidarCodigoPageModule)
    },
    {
        path: 'detalhes-produto/:idProduto',
        loadChildren: () => import('./pages/detalhes-produto/detalhes-produto.module').then(m => m.DetalhesProdutoPageModule),
        data: {
            tituloPagina: 'Detalhes do produto'
        }
    },
    {
        path: 'todas-avaliacoes-produto/:idProduto',
        loadChildren: () => import('./pages/todas-avaliacoes-produto/todas-avaliacoes-produto.module').then(m => m.TodasAvaliacoesProdutoPageModule),
        data: {
            tituloPagina: 'Todas as avaliações'
        }
    },
    {
        path: 'detalhes-parceiro/:idParceiro',
        loadChildren: () => import('./pages/detalhes-parceiro/detalhes-parceiro.module').then(m => m.DetalhesParceiroPageModule),
        data: {
            tituloPagina: 'Detalhes do parceiro'
        }
    },
    // {
    //     path: 'todas-avaliacoes-parceiro/:idParceiro',
    //     loadChildren: () => import('./pages/todas-avaliacoes-parceiro/todas-avaliacoes-parceiro.module').then(m => m.TodasAvaliacoesProdutoPageModule),
    //     data: {
    //         tituloPagina: 'Todas as avaliações'
    //     }
    // },
    {
        path: 'carrinho',
        loadChildren: () => import('./pages/carrinho/carrinho.module').then(m => m.CarrinhoPageModule),
        resolve: {
            valoresCarrinho: CalcularCarrinhoGuard
        },
        data: {
            tituloPagina: 'Carrinho'
        }
    },
    {
        path: 'finalizar-pedido',
        loadChildren: () => import('./pages/finalizar-pedido/finalizar-pedido.module').then(m => m.FinalizarPedidoPageModule),
        resolve: {
            valoresCarrinho: CalcularCarrinhoGuard
        },
        data: {
            tituloPagina: 'Finalizar pedido'
        },
        canActivate: [ValidateIsLoggedGuard]
    },
    {
        path: 'produtos-favoritos',
        loadChildren: () => import('./pages/produtos-favoritos/produtos-favoritos.module').then(m => m.ProdutosFavoritosPageModule),
        data: {
            tituloPagina: 'Favoritos'
        },
        canActivate: [ValidateIsLoggedGuard]
    },
    {
        path: 'produtos-categoria/:idCategoria',
        loadChildren: () => import('./pages/produtos-categoria/produtos-categoria.module').then(m => m.ProdutosCategoriaPageModule),
        data: {
            tituloPagina: 'Listagem de produtos'
        }
    },
    {
        path: 'produtos-subcategoria/:idSubcategoria',
        loadChildren: () => import('./pages/produtos-categoria/produtos-categoria.module').then(m => m.ProdutosCategoriaPageModule),
        data: {
            tituloPagina: 'Listagem de produtos'
        }
    },
    {
        path: 'produtos-loja/:idLoja',
        loadChildren: () => import('./pages/produtos-categoria/produtos-categoria.module').then(m => m.ProdutosCategoriaPageModule),
        data: {
            tituloPagina: 'Listagem de produtos'
        }
    },
    {
        path: 'recebemos-seu-pedido/:id',
        loadChildren: () => import('./pages/recebemos-seu-pedido/recebemos-seu-pedido.module').then(m => m.RecebemosSeuPedidoPageModule),
        data: {
            tituloPagina: 'Recebemos seu pedido'
        },
        canActivate: [ValidateIsLoggedGuard]
    },
    {
        path: 'informacoes-member',
        loadChildren: () => import('./pages/informacoes-member/informacoes-member.module').then(m => m.InformacoesMemberPageModule),
        canActivate: [ValidateIsLoggedGuard]
    },
    {
        path: 'qrcode-enviado',
        loadChildren: () => import('./pages/qrcode-enviado/qrcode-enviado.module').then(m => m.QrcodeEnviadoPageModule),
        data: {
            tituloPagina: 'QRCode enviado'
        },
        canActivate: [ValidateIsLoggedGuard]
    },
    {
        path: 'avaliacao-produto-vendedor',
        loadChildren: () => import('./pages/avaliacao-produto-vendedor/avaliacao-produto-vendedor.module').then(m => m.AvaliacaoProdutoVendedorPageModule),
        data: {
            tituloPagina: 'Avaliação de produto/vendedor'
        }
    },
    {
        path: 'pesquisa-produtos/:pesquisa',
        loadChildren: () => import('./pages/pesquisa-produtos/pesquisa-produtos.module').then(m => m.PesquisaProdutosPageModule),
        data: {
            tituloPagina: 'Pesquisa de produtos'
        }
    },
    {
        path: 'formulario-parceiro',
        loadChildren: () => import('./pages/formulario-parceiro/formulario-parceiro.module').then( m => m.FormularioParceiroPageModule),
        data: {
            tituloPagina: 'Formulário de parceiro'
        }
    },
    { path: '**', pathMatch: 'full', redirectTo: '' },


];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
