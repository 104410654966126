import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable({ providedIn: 'root' })
export class DadosHome {

    constructor(private api: ApiService) { }
    async resolve() {

        const resposta = await this.api.get('home');
        return resposta;

    }
}