import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../../interfaces/apiResponse';
import { UtilsService } from '../utils/utils.service';
import { SessionService } from '../session/session.service';
import { AuthService } from '../auth/auth.service';

import AES256 from 'aes-everywhere';
import { catchError, map, of, tap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    private url: string;
    private header: HttpHeaders;

    // Guarda a assinatura da criptografia do servidor
    private key: string;

    constructor(
        private _http: HttpClient,
        private utils: UtilsService,
        private session: SessionService,
        private auth: AuthService
    ) { this.url = environment.urlApi; }

    public async get(endpoint: string, data: any = {}): Promise<ApiResponse> {

        var encrypt = await this.utils.getStorage('encrypt') ?? 0;

        // Guarda a assinatura da criptografia local
        let key: string;

        if (encrypt === 1) {

            // Valida se o app tem a assinatura 
            if (this.key === undefined || this.key === null) {
                this.key = await this.getToken();
            }

            key = AES256.decrypt(this.key, environment.PASSPHRASE_AUTH);

        }

        await this.atualizarHeader();

        const promise: any = new Promise(async (resolve: any) => {

            try {

                // Valida se tem parametros na api
                if (data != '' || data != null) {
                    if (encrypt === 1) {

                        // Criptografando os parametros da api
                        data = {
                            data: AES256.encrypt(JSON.stringify(data), key)
                        };

                    }
                }

                this._http.get(this.url + endpoint + '/', {
                    params: data,
                    headers: this.header
                }).pipe(map(
                    async (res: any) => {

                        const retorno: ApiResponse = {
                            status: res.status,
                            responseCode: res.responseCode,
                            message: res.message,
                            data: res.data,
                        };

                        if (retorno.responseCode === 401) {
                            await this.session.logout();
                        }

                        try {

                            if (retorno.data !== undefined && encrypt === 1) {
                                retorno.data = JSON.parse(AES256.decrypt(retorno.data, key));
                            }

                            resolve(retorno);

                        } catch (error) {
                            console.log(error);
                            await this.session.logout();
                            resolve({ status: 'error' });
                        }

                    }),

                    catchError(async (res: HttpErrorResponse) => {

                        if (res.status === 401) {
                            await this.session.logout();
                        }

                        if (res.error.data !== undefined && encrypt === 1) {
                            res.error.data = JSON.parse(AES256.decrypt(res.error.data, key));
                        }

                        resolve(res.error);

                    }
                    )).subscribe(dados => resolve(dados));

            } catch (error) {
                console.log(error);
                this.utils.exibirToast('Ocorreu um erro, tente novamente!', 'error');
                resolve({ status: 'error' });
            }

        });

        return promise;

    }

    public async post(endpoint: string, data: any = {}): Promise<ApiResponse> {

        var encrypt = await this.utils.getStorage('encrypt') ?? 0;

        let key: string;

        if (encrypt === 1) {

            if (this.key === undefined || this.key === null) {
                this.key = await this.getToken();
            }

            key = AES256.decrypt(this.key, environment.PASSPHRASE_AUTH);

        }

        if (encrypt === 1) {
            key = AES256.decrypt(this.key, environment.PASSPHRASE_AUTH);
        }

        await this.atualizarHeader();

        const promise: any = new Promise(async (resolve: any) => {

            try {

                if (data != '' || data != null) {

                    if (encrypt === 1) {
                        data = {
                            data: AES256.encrypt(JSON.stringify(data), key)
                        };
                    }

                }

                this._http.post(this.url + endpoint, JSON.stringify(data), {
                    headers: this.header
                })
                    .pipe(map(
                        async (res: any) => {

                            const retorno: ApiResponse = {
                                status: res.status,
                                responseCode: res.responseCode,
                                message: res.message,
                                data: res.data,
                            };

                            if (retorno.responseCode === 401) {
                                await this.session.logout();
                            }

                            try {

                                if (retorno.data !== undefined && encrypt === 1) {
                                    retorno.data = JSON.parse(AES256.decrypt(retorno.data, key));
                                }

                                return retorno;

                            } catch (error) {
                                console.log(error);
                                await this.session.logout();
                                return { status: 'erro' };
                            }

                        }),

                        catchError(async (res: HttpErrorResponse) => {

                            if (res.status === 401) {
                                await this.session.logout();
                            }

                            if (res.error.data !== undefined && encrypt == 1) {
                                res.error.data = JSON.parse(AES256.decrypt(res.error.data, key));
                            }

                            return res.error;

                        })

                    ).subscribe(dados => resolve(dados));

            } catch (error) {
                console.log(error);
                this.utils.exibirToast('Ocorreu um erro, tente novamente!', 'error');
                resolve({ status: 'erro' });
            }

        });

        return promise;

    }

    public async put(endpoint: string, data: any = {}): Promise<ApiResponse> {

        var encrypt = await this.utils.getStorage('encrypt') ?? 0;

        let key: string;

        if (encrypt === 1) {

            if (this.key === undefined || this.key === null) {
                this.key = await this.getToken();
            }

            key = AES256.decrypt(this.key, environment.PASSPHRASE_AUTH);

        }

        if (encrypt === 1) {
            key = AES256.decrypt(this.key, environment.PASSPHRASE_AUTH);
        }

        await this.atualizarHeader();

        const promise: any = new Promise(async (resolve: any) => {

            try {

                if (data != '' || data != null) {

                    if (encrypt === 1) {
                        data = {
                            data: AES256.encrypt(JSON.stringify(data), key)
                        };
                    }

                }

                this._http.put(this.url + endpoint, JSON.stringify(data), {
                    headers: this.header
                })
                    .pipe(map(
                        async (res: any) => {

                            const retorno: ApiResponse = {
                                status: res.status,
                                responseCode: res.responseCode,
                                message: res.message,
                                data: res.data,
                            };

                            if (retorno.responseCode === 401) {
                                await this.session.logout();
                            }

                            try {

                                if (retorno.data !== undefined && encrypt === 1) {
                                    retorno.data = JSON.parse(AES256.decrypt(retorno.data, key));
                                }

                                resolve(retorno);

                            } catch (error) {
                                console.log(error);
                                await this.session.logout();
                                resolve({ status: 'error' });
                            }

                        }),

                        catchError(async (res: HttpErrorResponse) => {

                            if (res.status === 401) {
                                await this.session.logout();
                            }

                            if (res.error.data !== undefined && encrypt === 1) {
                                res.error.data = JSON.parse(AES256.decrypt(res.error.data, key));
                            }

                            resolve(res.error);

                        })

                    ).subscribe(dados => resolve(dados));

            } catch (error) {
                this.utils.exibirToast('Ocorreu um erro, tente novamente!', 'error');
                resolve({ status: 'error' });
            }

        });

        return promise;

    }

    public async delete(endpoint: string, data: any = {}): Promise<ApiResponse> {

        var encrypt = await this.utils.getStorage('encrypt') ?? 0;

        let key: string;

        if (encrypt === 1) {

            if (this.key === undefined || this.key === null) {
                this.key = await this.getToken();
            }

            key = AES256.decrypt(this.key, environment.PASSPHRASE_AUTH);

        }

        if (encrypt === 1) {
            key = AES256.decrypt(this.key, environment.PASSPHRASE_AUTH);
        }

        await this.atualizarHeader();

        const promise: any = new Promise(async (resolve: any) => {

            try {

                if (data != '' || data != null) {

                    if (encrypt === 1) {
                        data = {
                            data: AES256.encrypt(JSON.stringify(data), key)
                        };
                    }

                }

                this._http.delete(this.url + endpoint + '/', {
                    params: data,
                    headers: this.header
                }).pipe(map(

                    async (res: any) => {

                        const retorno: ApiResponse = {
                            status: res.status,
                            responseCode: res.responseCode,
                            message: res.message,
                            data: res.data,
                        };

                        if (retorno.responseCode === 401) {
                            await this.session.logout();
                        }

                        try {

                            if (retorno.data !== undefined && encrypt === 1) {
                                retorno.data = JSON.parse(AES256.decrypt(retorno.data, key));
                            }

                            resolve(retorno);

                        } catch (error) {
                            console.log(error);
                            await this.session.logout();
                            resolve({ status: 'error' });
                        }

                    }),

                    catchError(

                        async (res: HttpErrorResponse) => {

                            if (res.status === 401) {
                                await this.session.logout();
                            }

                            if (res.error.data !== undefined && encrypt === 1) {
                                res.error.data = JSON.parse(AES256.decrypt(res.error.data, key));
                            }

                            resolve(res.error);

                        })
                ).subscribe(dados => resolve(dados));

            } catch (error) {
                console.log(error);
                this.utils.exibirToast('Ocorreu um erro, tente novamente!', 'error');
                resolve({ status: 'error' });
            }

        });

        return promise;

    }

    private async atualizarHeader() {

        const jwt = await this.utils.getStorage('usr_jwt');
        const encrypt = await this.utils.getStorage('encrypt') ?? 0;
        const versao = await this.utils.getStorage('versao') ?? 'Server';

        if (jwt != null) {

            this.header = new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + jwt,
                Encrypt: encrypt === 1 ? 'true' : 'false',
                'AppPlatform': this.utils.plataforma().platform,
                'AppVersion': versao
            });

        } else {

            this.header = new HttpHeaders({
                'Content-Type': 'application/json',
                Encrypt: encrypt === 1 ? 'true' : 'false',
                'AppPlatform': this.utils.plataforma().platform,
                'AppVersion': versao
            });

        }

    }

    // Função para pegar a assinatura da criptografia
    public async getToken() {

        const encrypt = await this.utils.getStorage('encrypt') ?? 0;

        const promise: any = new Promise(async (resolve: any) => {

            try {

                // ambiente + endpoint criptografada para pegar a assinatura
                this._http.get(this.url + this.auth.gerarEndpoint() + '/', {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        Encrypt: encrypt === 1 ? 'true' : 'false',
                    })
                })
                    .pipe(tap({
                        next: async (res: any) => {

                            const retorno = {
                                responseCode: res.responseCode
                            }

                            if (res.responseCode === 401) {
                                await this.session.logout();
                            }

                            resolve(res.data);

                        },
                        error: async (res: HttpErrorResponse) => {

                            if (res.status === 401) {
                                await this.session.logout();
                            }

                        }
                    }));

            } catch (error) {

                this.utils.exibirToast('Ocorreu um erro, tente novamente!', 'error');

            }

        });

        return promise;

    }

    // Função para buscar o CEP
    public async getCEP(endpoint: string): Promise<ApiResponse> {

        const promise: any = new Promise(async (resolve: any) => {

            try {

                this._http.get(endpoint).pipe(map(
                    async (res: any) => {

                        const retorno: ApiResponse = {
                            status: 'sucesso',
                            responseCode: 200,
                            message: 'CEP Encontrado',
                            data: res.success,
                        };

                        try {
                            resolve(retorno);
                        } catch (error) {
                            resolve({ status: 'error' });
                        }

                    }),

                    catchError(async (res: HttpErrorResponse) => {

                        if (res.status === 401) {
                            await this.session.logout();
                        }

                        resolve(res.error);

                    }
                    )).subscribe(dados => resolve(dados));

            } catch (error) {
                resolve({ status: 'error' });
            }

        });

        return promise;

    }

}
