import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-validar-codigo',
  templateUrl: './validar-codigo.page.html',
  styleUrls: ['./validar-codigo.page.scss'],
})
export class ValidarCodigoPage implements OnInit {

  @Input() titulo: string;
  @Input() mensagem: string;

  public carregando: boolean = false;

  public codigo: string;
  public email: any;
  public form = {
    codigo: { mostrarErro: false, mensagem: '' }
  };

  constructor(
    private modalCtrl: ModalController,
    private utils: UtilsService,
    private api: ApiService
  ) { }

  async ngOnInit() {
    let dados = await this.utils.getStorage("origemEmail");

    this.email = {
      email: dados.email,
    }
  }

  async confirmarCodigo() {
    if (this.codigo.length < 6) {
      this.utils.exibirToast("Código de autorização inválido", "error");
    }

    let email = await this.utils.getStorage("origemEmail");

    const req = {
      codigo: this.codigo,
      email: email.email
    };

    const res = await this.api.post('validar_codigo', req);

    switch (res.status) {
      case 'success':
        this.utils.setStorage("jwt", res.data.jwt);
        this.utils.navigateForward('alterar-senha');
        break;
      case 'empty':
        this.modalCtrl.dismiss({ status: 'empty' });
        // this.utils.exibirModalMensagem('Sua sessão expirou. Faça login novamente.').then(res2 => {
        //   if (res2.data === 'fechar') {
        //     this.utils.sessaoExpirada();
        //   }
        // });
        break;
      default:
        this.utils.exibirToast(res.message, "error");
        break;
    }

  }

  // fecharModal() {
  //     this.modalCtrl.dismiss();
  // }


}
