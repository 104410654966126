import { Parceiro } from './../../interfaces/Parceiro';
import { Injectable } from '@angular/core';
import { UtilsService } from '../utils/utils.service';
import { Produto } from 'src/app/interfaces/produto';
import { LocalStorage } from 'src/app/types/localstorage';
import { ValoresCarrinho } from 'src/app/interfaces/valoresCarrinho';
import { ApiService } from '../api/api.service';

@Injectable({
    providedIn: 'root'
})
export class CarrinhoService {

    private readonly CARRINHO: LocalStorage = 'carrinho';

    constructor(
        private utils: UtilsService,
        private api: ApiService
    ) { }

    async adicionarItemCarrinho(item: Produto, quantidade: number) {

        let carrinho = await this.utils.getStorage(this.CARRINHO) ? await this.utils.getStorage(this.CARRINHO) : []

        let indiceParceiro = carrinho.findIndex((elemento: any) => elemento.id_parceiro === item.id_parceiro);

        let indiceProduto = carrinho.findIndex((elemento: any) => elemento.produtos.some((produto: any) => produto.id === item.id))

        let novoItemCarrinho = {
            nome_parceiro: item.nome_parceiro,
            id_parceiro: item.id_parceiro,
            produtos: [{
                id: item.id,
                nome: item.nome,
                imagem_1: item.imagem_1,
                valor_final: item.valor_final,
                quantidade: quantidade,
                quantidade_estoque: item.quantidade_estoque
            }]
        };

        if (indiceParceiro !== -1) {
            if (indiceProduto === -1) {
                // se tiver parceiro, mas não tiver produto
                carrinho[indiceParceiro].produtos.push({
                    id: item.id,
                    nome: item.nome,
                    imagem_1: item.imagem_1,
                    valor_final: item.valor_final,
                    quantidade: quantidade,
                    quantidade_estoque: item.quantidade_estoque
                });
            } else {
                // se tiver parceiro e tiver produto
                let produtoExistente = carrinho[indiceParceiro].produtos.find((produto: any) => produto.id === item.id);
                produtoExistente.quantidade += quantidade;
            }
        } else {
            // se não tiver parceiro
            carrinho.push(novoItemCarrinho);
        }

        this.utils.setStorage(this.CARRINHO, carrinho);
    }

    async pegarCarrinho() {

        let carrinho = await this.utils.getStorage(this.CARRINHO) ? await this.utils.getStorage(this.CARRINHO) : [];

        return carrinho;

    }

    async atualizarCarrinho(carrinho: any) {

        await this.utils.setStorage(this.CARRINHO, carrinho);

    }

    limparCarrinho() {
        this.utils.removeStorage(this.CARRINHO);
    }

    async calcularCarrinho() {

        const retorno: ValoresCarrinho = {
            quantidade_itens: 0,
            valor_frete: 0,
            valor_produtos: 0,
            valor_total: 0,
            valor_total_carrinho: 0
        };

        let carrinho = await this.utils.getStorage(this.CARRINHO) ? await this.utils.getStorage(this.CARRINHO) : [];

        // LAÇO DE PARCEIROS
        for (let indexParceiro = 0; indexParceiro < carrinho.length; indexParceiro++) {

            const parceiro = carrinho[indexParceiro];

            // LAÇO DE PRODUTOS
            for (let indexProduto = 0; indexProduto < parceiro.produtos.length; indexProduto++) {

                const produto: Produto = parceiro.produtos[indexProduto];

                retorno.quantidade_itens += produto.quantidade;
                retorno.valor_produtos += Number(produto.valor_final) * produto.quantidade;

            }

        }

        // Validar o valor do frete

        const validar_frete = await this.calcularFrete(carrinho);

        retorno.valor_frete = validar_frete;
        retorno.valor_total = retorno.valor_frete + retorno.valor_produtos;

        return retorno;

    }

    async calcularQuantidadeCarrinho() {

        const retorno: ValoresCarrinho = {
            quantidade_itens: 0,
            valor_frete: 0,
            valor_produtos: 0,
            valor_total: 0
        };

        let carrinho = await this.utils.getStorage(this.CARRINHO) ? await this.utils.getStorage(this.CARRINHO) : [];

        // LAÇO DE PARCEIROS
        for (let indexParceiro = 0; indexParceiro < carrinho.length; indexParceiro++) {

            const parceiro = carrinho[indexParceiro];

            // LAÇO DE PRODUTOS
            for (let indexProduto = 0; indexProduto < parceiro.produtos.length; indexProduto++) {

                const produto: Produto = parceiro.produtos[indexProduto];
                retorno.quantidade_itens += produto.quantidade;

            }

        }

        return retorno

    }

    async calcularFrete(carrinho: any) {

        const resposta = await this.api.post('validar_frete', { produtos: carrinho });

        if (resposta.status != 'sucesso') {
            this.utils.exibirToast(resposta.message);
            return 12.99;
        }

        return resposta.data.valor_total_carrinho;

    }

}
