import { Injectable, Type } from '@angular/core';
import { NavController, ToastController, ModalController, Platform, LoadingController } from '@ionic/angular';
import { Preferences } from '@capacitor/preferences';
import { PlatformDevice } from 'src/app/interfaces/platformDevice';
import { RequisitosSenha } from 'src/app/interfaces/ipApi';
import { ValidarCodigoPage } from 'src/app/modals/validar-codigo/validar-codigo.page';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    // private database: Database = inject(Database);
    constructor(
        private platform: Platform,
        private modalController: ModalController,
        private loadingController: LoadingController,
        private navController: NavController,
        private toastController: ToastController,
    ) { }

    // VALIDAR CAMPOS
    public validarEmail(email: string) {
        const er = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return er.exec(email) != null;
    }

    public validarSenha(senha: string) {
        let regexLetra = /[A-z]/;
        let regexNumero = /[0-9]/;

        const requisitosSenha: RequisitosSenha = {
            tamanho: senha.length >= 8,
            letra: regexLetra.test(senha),
            numero: regexNumero.test(senha)
        }

        return Object.values(requisitosSenha).every(regra => regra === true);
    }

    public validarCPF(cpf: string) {

        cpf = cpf.replace(/\D/g, '');

        let soma = 0;
        let resto: number;

        if (cpf === '00000000000') {
            return false;
        }

        for (let index = 1; index <= 9; index++) {
            soma += parseInt(cpf.substring(index - 1, index), 10) * (11 - index);
        }

        resto = (soma * 10) % 11;
        resto = resto === 10 || resto === 11 ? 0 : resto;

        if (resto !== parseInt(cpf.substring(9, 10), 10)) {
            return false;
        }

        soma = 0;

        for (let index = 1; index <= 10; index++) {
            soma += parseInt(cpf.substring(index - 1, index), 10) * (12 - index);
        }

        resto = (soma * 10) % 11;
        resto = resto === 10 || resto === 11 ? 0 : resto;

        if (resto !== parseInt(cpf.substring(10, 11), 10)) {
            return false;
        }

        return true;

    }

    // TOAST
    public async exibirToast(mensagem: string, status: ('success' | 'error' | 'info') = 'success', margin: ('mt-toast-12' | 'mt-toast-30') = 'mt-toast-12') {

        const configIcon = {
            'success': 'heart',
            'error': 'heart-dislike-outline',
            'info': 'information-circle-outline'
        };

        const toast = await this.toastController.create({
            position: 'top',
            message: mensagem,
            buttons: [{ icon: 'close-outline' }],
            cssClass: `toast-${status} ${margin}`,
            duration: 3000,
            icon: configIcon[status],
            mode: 'ios'
        });

        return await toast.present();
    }

    // MANAGEMENT MODAL

    public async exibirLoading() {
        const loading = await this.loadingController.create({
            spinner: 'circles',
            cssClass: 'ion-loading-personalizado'
        });

        await loading.present();


        setTimeout(() => {
            loading.dismiss();
        }, 30000);

    }

    public async ocultarLoading() {
        try {
            await this.loadingController.dismiss();
        } catch (error) {
            false;
        }
    }

    public async exibirValidarCodigo(texto: string) {

        const modal = await this.modalController.create({
          component: ValidarCodigoPage,
          componentProps: {
            texto
          },
          showBackdrop: false,
          cssClass: 'fundoTransparent'
        });
    
        await modal.present();
        return modal.onDidDismiss();
      }

    async abrirModal<T>(
        page: Type<T>,
        cssClass: string = '',
        props?: object,
        callback?: Function,
        backdropDismiss: boolean = true,
        mode: 'ios' | 'md' = 'ios') {

        const modal = await this.modalController.create({
            component: page,
            cssClass,
            mode: mode,
            componentProps: props,
            animated: true,
            backdropDismiss,
            keyboardClose: true
        });

        modal.onDidDismiss().then((props) => {

            if (callback) {
                callback(props);
            }

        });

        await modal.present();

        return modal;

    }

    // NAVIGATION
    public async navigateForward(pagina: string, queryParams: object = {}, animacao: boolean = true): Promise<void> {
        await this.navController.navigateForward(pagina, { animated: animacao, queryParams });
    }

    public navigateRoot(pagina: string, queryParams: object = {}, animacao: boolean = true): void {
        this.navController.navigateRoot(pagina, { animated: animacao, queryParams, replaceUrl: true });
    }

    public navigateBack(pagina: string, queryParams: object = {}, animacao: boolean = true): void {
        this.navController.navigateBack(pagina, { animated: animacao, queryParams });
    }

    public back(animacao: boolean = true): void {
        this.navController.back({ animated: animacao });
    }

    // STORAGE
    public async setStorage<T>(key: string, valor: T): Promise<void> {
        await Preferences.set({
            key: `powshare.${key}`,
            value: JSON.stringify(valor),
        });
    }

    public async getStorage(key: string): Promise<any> {
        const data = await Preferences.get({ key: `powshare.${key}` });

        try {
            return JSON.parse(data.value ?? '');
        } catch (e) {
            return data.value;
        }
    }

    public async removeStorage(key: string): Promise<void> {
        await Preferences.remove({ key: `powshare.${key}` });
    }

    public async checkStorage(key: string): Promise<boolean> {
        return (await Preferences.get({ key: `powshare.${key}` })).value == null;
    }

    public async clearStorage() {
        await Preferences.clear();
    }

    public plataforma(): PlatformDevice {

        let platformDevice: PlatformDevice = {
            device: 'desktop',
            platform: 'desktop'
        };

        if (this.platform.is('mobile')) platformDevice.device = 'mobile';


        if (this.platform.is('android')) platformDevice.platform = 'android';
        if (this.platform.is('ios')) platformDevice.platform = 'ios';
        if (this.platform.is('mobileweb')) platformDevice.platform = 'mobileweb';

        return platformDevice;

    }

    setReaisMask(event: any, valorEvent: string = '', number?: number) {

        const hasSpecialCharacter = /[^\d]/g;
        const hasOnlyZeros = /^0+$/;
        const keyboardInput = event.detail.event.data;

        while (valorEvent.charAt(0) === '0') {
            valorEvent = valorEvent.substring(1);
        }

        if (keyboardInput == null && event.target.value == '') {
            valorEvent = ''
            return {
                event: '',
                valorEvent,
                number: 0
            }
        }

        //Remove a vírgula caso não haja nenhum número a direita
        if (event.target.value.slice(-1) == ',') {
            event.target.value = event.target.value.slice(0, -1)            
            //Se após isso o número da esquerda for apenas 0, limpa a input
            if (parseInt(event.target.value) == 0) {
                event.target.value = ''
                return {
                    event: event.target.value,
                    valorEvent,
                    number: 0
                }
            }

            
            valorEvent = event.target.value;
            return {
                event: event.target.value,
                valorEvent,
                number: parseFloat(valorEvent)
            }

        }

        //Se o usuário não digitou nenhum caracter (Ex: comando de DELETE)
        if (keyboardInput == null) {
            valorEvent = valorEvent.slice(0, -1);
            return {
                event: event.target.value,
                valorEvent,
                number: 0
            }
        }

        if (hasOnlyZeros.test(event.target.value)) {
            return {
                event: '',
                valorEvent,
                number: 0
            }
        }

        if (!hasSpecialCharacter.test(keyboardInput)) {
            valorEvent += keyboardInput;
        }
        else {
            if (/\d/.test(event.target.value)) {
                event.target.value = event.target.value.slice(0, -1)
            }
            else {
                event.target.value = ''
                return false;
            }
        }

        if (valorEvent) {
            let aux = valorEvent;
            switch (aux.length) {
                case 1:
                    aux = valorEvent
                    aux = '0,0' + aux
                    break;

                case 2:
                    aux = valorEvent
                    aux = '0,' + aux
                    break;
                case 3:
                    aux = this.addCommaAtPosition(aux, 1)
                    break;
                case 4:
                    aux = this.addCommaAtPosition(aux, 2)
                    break;
                default:
                    aux = this.addCommaAtPosition(aux, aux.length - 2)
            }

            event.target.value = aux;

            number = parseFloat(valorEvent);
            number = number / 100;
            return {
                event: event.target.value,
                number,
                valorEvent
            }
        }
        return false;
    }

    public addCommaAtPosition(inputString: string, position: number) {
        if (position >= 0) {
            let res = inputString.slice(0, position) + ',' + inputString.slice(position);
            return res;
        } else {
            console.log("Invalid position provided");
            return inputString;
        }
    }

}
