// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    envName: 'dev',

    urlApi: 'https://powshare.ws01.mobi/api/',
    // urlApi: 'http://powshare.mac/api/',
    PASSPHRASE_AUTH: '',
    authApi: {
        dias: {
            1: '7LaTO',
            2: '4IUtc',
            3: 'SCsxF',
            4: '0iHZD',
            5: 'V7wYF',
            6: 'XlKZb',
            7: '28Fm1',
            8: 'PkAKh',
            9: '1Xu2g',
            10: '4QlWz',
            11: 'TklWy',
            12: 'ocMTD',
            13: 'U2swM',
            14: 'gFBaZ',
            15: '6sLjw',
            16: 'oYJw3',
            17: 'WVPZv',
            18: 'DaVLM',
            19: 'bwqiH',
            20: '3laOJ',
            21: 'zP6td',
            22: 'f1omD',
            23: '1a6L8',
            24: 'K19xF',
            25: '2PTE8',
            26: 'CUAWu',
            27: 'KspT5',
            28: 'WkYhG',
            29: '6Cm2n',
            30: 'gtWho',
            31: '8GqTa'
        },
        semana: {
            0: 'J68rK',
            1: 'gu2OJ',
            2: '2k7o3',
            3: 'nyBzK',
            4: 'WaTbH',
            5: 'o67Ly',
            6: 'Esmcv',
        },
        meses: {
            1: 'CouhQ',
            2: 'aVHsN',
            3: 'SjFws',
            4: '5HA8r',
            5: 'V4uZ8',
            6: '4rGNa',
            7: 'qWhcP',
            8: 'fQRgr',
            9: 'njxZo',
            10: '2yc4k',
            11: 'HS1fA',
            12: 'IrFlB'
        }
    },
    apiCepUrl: 'https://cep.alphacode.com.br/action/cep/',

    booksApi: 'https://books-api-qa.wrdr.io',
    userAPI: 'https://user-api-qa.wrdr.io',
    settingsAPI: 'https://settings-api-qa.wrdr.io',
    orgAPI: 'https://org-api-qa.wrdr.io',

    clientId: {
        'android': 'org.worldreader.booksmart.pwa.android',
        'ios': 'org.worldreader.booksmart.pwa.ios',
        'web': 'org.worldreader.booksmart.pwa.web',
        'desktop': 'org.worldreader.booksmart.pwa.desktop'
    },
    clientToken: {
        'android': 'w8JUrN5S1EZc84C3sZzeAZZP',
        'ios': '9ZeVZyYqHqN145z8dui6ahPV',
        'web': 'gHX6mTKc4oL7k83OSUAI2dSh',
        'desktop': 'wyVr2TnlTeQj8cAoUG557R0M',
    },

    googleClientId: "430840268913-1nhp9uunikipfqucv46bc9vdn3td1dp3.apps.googleusercontent.com",
    awsRegion: 'eu-west-1',
    awsUser: 'amplify-user',
    awsAccessKey: 'AKIAWGJKXABDKBN5CBHF',
    awsSecretKey: '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',
    ppAwsAppId: 'c2803e7d2aac48ee819bfe301a0de95c',

    googleAnalyticsTracking: 'GTM-T3QCX6HB',

    coverImageSize: '120x160',
    fullWidthImageSize: '240x320',

    userAgentsBlacklist: [
        'ELB-HealthChecker/2.0'
    ],

    ipApiKey: 'M54ywotVvu1DaNK',
    hostWeb: 'booksmart-pwa-dev.worldreader.org'
};
