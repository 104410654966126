import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CacheInterceptor } from './interceptors/cache.interceptor';

import { environment } from 'src/environments/environment';
import { TitleCasePipe } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';

import ptBr from "@angular/common/locales/pt";
import { registerLocaleData } from "@angular/common";

registerLocaleData(ptBr);

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production
        })
    ],
    providers: [
        TitleCasePipe,
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy
        },
        { provide: LOCALE_ID, useValue: "pt" },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: CacheInterceptor,
        //     multi: true,
        // },
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
