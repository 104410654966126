import { Injectable } from '@angular/core';
import { CookiesService } from '../cookies/cookies.service';
import { LocalStorage } from "../../types/localstorage";
import { UtilsService } from '../utils/utils.service';
import { Usuario } from 'src/app/interfaces/usuario';
import { ModalMensagemPage } from 'src/app/modals/modal-mensagem/modal-mensagem.page';
import { BehaviorSubject, Observable } from 'rxjs';
import { EventsService } from '../events/events.service';

@Injectable({
    providedIn: 'root'
})
export class SessionService {

    private readonly USR_JWT: LocalStorage = 'usr_jwt';
    private readonly USR_PERFIL: LocalStorage = 'usr_perfil';
    public verificacaoLogin = new BehaviorSubject<boolean>(false);

    constructor(
        private cookies: CookiesService,
        private utils: UtilsService,
        private events: EventsService
    ) { }

    async isLogged() {
        return (await this.utils.getStorage(this.USR_JWT) ?? '') != '' && (await this.utils.getStorage(this.USR_PERFIL) ?? '') != '';
    }

    async obterVerificacaoLogin() {
        let verificacao = await this.isLogged()
        if(verificacao) {
            this.verificacaoLogin.next(true)
        }
        return this.verificacaoLogin.value
    }

    async login(jwt: string, perfil: Usuario) {
        await this.utils.setStorage(this.USR_JWT, jwt);
        await this.utils.setStorage(this.USR_PERFIL, JSON.stringify(perfil));
        // this.verificacaoLogin.next(true)
        this.events.publish('atualizar_status_login')
        this.events.publish('atualizar_exibicao_favorito')
    }

    async logout() {

        this.utils.removeStorage(this.USR_JWT);
        this.utils.removeStorage(this.USR_PERFIL);

        this.utils.navigateRoot('login');

        // this.utils.abrirModal(ModalMensagemPage, 'modal-mensagem', { titulo: 'Sessão expirada', mensagem: 'Faça login novamente para renovar sua sessão.' }, () => {
        //     this.utils.navigateRoot('login');
        // });
        this.events.publish('atualizar_status_login')
        this.events.publish('atualizar_exibicao_favorito')

    }

    async setPerfil(dados: Usuario) {
        await this.utils.setStorage(this.USR_PERFIL, JSON.stringify(dados));
    }

    async getPerfil() {
        return JSON.parse(await this.utils.getStorage(this.USR_PERFIL));
    }

}
